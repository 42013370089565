#launchpadContent{
    padding-top: 15vh;
    align-items: flex-start;
    padding-bottom: 25vh;
    position: relative;
}
#launchpadContent #leftSide{
}
.container{
    max-width: 80% !important;
}
#l2{
    position: absolute;
    right: 0;
    filter: invert(1);
    width: 120px;
}
#l1{
    position: absolute;
    width: 80px;
    left: -50px;
    transform: rotate(-115deg);
    top: 80vh;
}
.tab-content{
    padding-top: 40px;
}
.spanItem label{
    font-size: 14px;
    opacity: 0.8;
}
#hcircle{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 350px;
    z-index: -1;
    margin-top: -60px;
}
#hlogo{
    width: 80%;
    max-width: 300px;
    margin-top: -100px;
    filter: drop-shadow(2px 4px 6px #271a24);
    margin-bottom: -50px;
}
#leftSideContainer .nav{
    justify-content: center;
}
#leftSideContainer .nav li{
    text-align: center !important;
}
.btn-primary.disabled, .btn-primary:disabled{
    background-color: #0d101d !important; 
    opacity: 0.6!important;
}

#treeBg{
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-size: cover !important;
}
#launchpadContent #leftSide h2{
    max-width: 100%;
    text-align: left;
    color: #f3f3f3;
    font-size: 16px;
    line-height: 27px;
    font-family: 'Gilroy';
    padding-bottom: 15px !important;
}
.tab-content>.active{
    border-bottom: none !important;
}

#launchpadContent #leftSide h1::after{
    display: none;
}

#launchpadContent .btnWrapper{
    display: flex;
    align-items: center;
    max-width: 100%;
}

.connectWallet small{
    display: block;
    font-weight: 100;
    font-size: 9px;
    opacity: 0.8;
}
.nav-tabs[role='tablist']{
    border: none !important;
}
.nav-tabs .nav-link[role='tab']{
    color: white;
    font-weight: 400;
    font-size: 17px;
    padding: 0px !important;
    outline: none !important;
    border: none !important;
    border-color: #3e3ad3 !important;
    transition: 0.4s all;
    opacity: 0.9;
    padding-right: 20px !important;
    padding-left: 20px !important;
    
}
.nav-tabs .nav-link[role='tab'].active{
    font-weight: 700;
    background: transparent;
    opacity: 1 !important;
    color:white !important;
    position: relative;
}
.nav-tabs .nav-link[role='tab'].active::after{
    content: '';
    width: 6px;
    height: 6px;
    background: white;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 100%;
}
#launchpadContent .connectWallet{
    padding: 8px 20px;
    border-radius: 5px;
    margin: 0;
    font-size: 15px;
}.team-item-section{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.team-item{
    text-align: left;
    flex: 0 0 50%;
}
.team-item.r{
    text-align: right !important;
}
.team-item.r .social{
    justify-content: flex-end;
}
.team-item.r .social img{
    margin-right: 0 !important;
    margin-left: 15px;
}
.mint-section{
    position: absolute;
    bottom: 10px;
    right: 15px;
}
@keyframes beat-animation {
    0%    {transform:scale(1);}
    25%   {transform:scale(1.02);}
    40%   {transform:scale(0.97);}
    75%   {transform:scale(1.05);}
    100%  {transform:scale(1);}
}

#topSpan{
    margin-top: 20px;
}
#topSpan span{
    border-radius: 5px;
    background: transparent;
    font-weight: 600;
    border-radius: 5px;
    border: 2px solid #6460ff;
    color: #6169ff;
    margin-bottom: 10px;
    display: inline-block;
    
    border-radius: 5px;
    background: white;
    font-weight: 900;
    border-radius: 5px;
    border: 1px solid #ee7c19;
    color: #da8f30;
    margin-bottom: 10px;
    display: inline-block;
    padding: 3px 8px;
    margin: 5px;
    box-shadow: 2px 2px 5px #8c3c1d52;

}
#launchpadContent .btnWrapper span{
    font-size: 15px;
    font-weight: 700;
    opacity: 1;
}

#loader img{
    width: 100px;
}
#loader p{
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
 } 
#loader{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: black;
}
#projectError{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
    font-weight: 700;
    text-shadow: 2px 2px 15px #3333d7;
}
#projectError div{
    animation: b-animation 2s infinite;
}
@keyframes b-animation {
    0%    {transform:scale(1);}
    25%   {transform:scale(1.05);}
    40%   {transform:scale(0.9);}
    75%   {transform:scale(1.1);}
    100%  {transform:scale(1);}
}
#projectBack{
    position: absolute;
    margin-top: -60px;
    font-size: 24px;
}

.social-item{
    padding: 0;
    margin: auto;
    padding: 8px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 15px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
}
.social-item img{
    width: 22px;
    padding-left: 0;
    transition: 0.4s all;
    filter: brightness(5);
}
#enleapLogo{
    width: 25px;
    filter: brightness(5);
    margin-bottom: -3px;
}
.social-item a{
    padding-left: 0 !important;
    padding-right: 25px !important;
}
.social-item img:hover{
    transform: scale(1.15);
    opacity: 0.7;
}
@media (max-width: 960px) {
    .container{
        max-width: 90% !important;
    }
    #rightSide{
        flex: 2;
    }
    #leftSide {
        flex: 5;
    }
    .nav-tabs .nav-link[role='tab']{
        font-size: 20px !important;
        padding: 5px 15px !important;
        margin-right: 20px !important;
    }
    #home{
        flex-direction: column;
    }
    #leftSide h2{
        padding: 15px 0 50px 0;
        max-width: 100%;
    }
    #imageWrapper{
        width: 80%;
        justify-content: center;
        margin:auto;
    }
   
    .btnWrapper{
        margin: 0 auto;
    }
    #aboutSection{
        background-size: cover;
    }
}

@media (max-width: 800px) {
    #leftSide{
        width: 100%;
        order: 2;
        margin: 50px 0;
    }
    
    #rightSide{
        width: 100%;
        order: 1;
        margin-top: 30px;
    }
    .container{
        max-width: 90% !important;
    }
    #rightSide{
        top: 0 !important;
    }
    #rightSide{
        position: relative !important;
    }
    #launchpadContent, #launchpadContent .btnWrapper{
        flex-direction: column;
    }
    #launchpadContent .btnWrapper span{
        margin-top: 25px;
        font-size: 15px;
    }
    #launchpadContent #leftSide{
        padding: 0px 5% !important;
    }
    #imageWrapper{
        width: 50%;
        margin-bottom: 100px;
    }
    #launchpadContent{
        padding-top: 2vh;
    }
}

@media (max-width: 560px) {
    .container{
        max-width: 100% !important;
    }
    .nav-tabs .nav-link[role='tab'].active::after{
        bottom: 0px !important;
    }
    .progress-container{
        max-width: 90% !important;
    }
    #leftSide .nav{
        flex-direction: column;
    }
    #leftSide .nav button{
        width: 100%;
        margin-bottom: 8px;
    }
    #imageWrapper{
        width: 90%;
        margin-bottom: 100px;
    }
    #leftSide{
        margin: 80px 0 50px 0;
    }
    .nav-tabs .nav-link[role='tab']{
        font-weight: 300;
    }
    #bottomSpan{
        flex-wrap: wrap;
    }
    .spanItem{
        margin-bottom: 15px;
    }
    .mint-section{
        position: relative;
        bottom: 0;
        right: 0;
        margin-top: 10px;
        margin-bottom: 60px;
    }
    .mint-info-list .countdown{
        bottom: 15px !important;
        top: initial !important;
    }
    .countdown span{
        margin-bottom: 0 !important;
    }
}


#gif-container{
    overflow: hidden;
    
}
#gif-container img{
    width: 100%;
}

#seedimg{
    width: 160px;
    margin-bottom: 10px;
    border-radius: 8px;
}
.custom-modal .modal-dialog{
    position: absolute!important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%)!important;
    transform: translate(-50%,-50%)!important;
    margin: 0!important;
    width: 350px;
    height: 300px;
}
.promptClose {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: rgb(206, 206, 206);
}
.modal-content{
    height: 100%;
    border-radius: 15px !important;
    background: #17100d !important;
    box-shadow: 0px 0px 15px #5c4cff;
    animation: backdrop 2s ease infinite;
}
.promptClose{
    font-weight: 900;
}
#l4{
    filter: brightness(5);
    width: 100px;
    margin-bottom: 20px; 
}
.hide{
    display: none !important;
}
@keyframes backdrop {
    0% {
        box-shadow: 0px 0px 15px #5c3f30;
    }
    50% {
        box-shadow: 0px 0px 65px #5b4c43;
    }
    100% {
        box-shadow: 0px 0px 15px #5c4130;
    }
  }

.modal-body{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-body p{
    color: #e2e2e2;
    font-size: 15px;
    text-align: center;
}
.modal-backdrop.show{
    opacity: 0.7 !important;
}
.prompt-modal .modal-body{
    padding: 25px 60px;
}
#promptMintBtn{
    border-radius: 5px;
    padding: 8px;
    font-size: 16px;
    margin-top: 25px;
}
.promptBody{
    text-align: center;
    display: flex;
    align-items: center;
}
#l5{
    width: 200px;
    margin: auto;
}
.promptBody p{
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 10px;
}
.promptBody h3{
    margin-bottom: 25px;
}
#mintQuantity{
    min-width: 200px;
    background-color: #2a2a3c !important;
    border: 1px solid #4b4b60 !important;
    margin-top: 5px;
    color: var(--default-font-color) !important;
}
.prompt-modal .modal-dialog{
    height: auto;
    width: auto !important;
}
.promptBody{
    text-align: left;
    margin-top: 25px;
    margin-bottom: 25px;
}
.promptBody h3{
    margin-top: 25px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
}
.fa-item {
    padding: 5%;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}

.fa-item img {
    width: 50px;
    margin-bottom: 15px;
}

.fa-item h3 {
    font-weight: 800;
    font-size: var(--header-font-size);
}

.fa-item p {
    font-size: var(--paragraph-font-size);
}

.fa-item button {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}

.fa-step {
    padding-left: 60px;
    position: relative;
    margin-top: 30px;
    padding: 15px 60px;
    color: black;
    text-align: left;
}

.fa-step .step-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    background:#634f51 !important;
    text-align: center;
    border-radius: 100%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    left: 15px;
    top: -3px;
}
.form-control[type='file']{
    background-color: #2a2a3c !important;
    border: 1px solid #4b4b60 !important;
    color: var(--default-font-color) !important;
}
.fa-step h3 {
    font-size: 15px;
    font-weight: 500;
    opacity: 0.9;
    margin-bottom: 10px;
}
.fa-step p {
    font-size: var(--paragraph-font-size);
    opacity: 0.9;
}
.fa-step a {
    color: #0014a1;
    font-weight: 600;
}
.fa-step::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #85797b !important;
    top: 22px;
    left: 27px;
}
.fa-step:last-child::after {
    background-color: transparent !important;
}





.fa-step {
    padding-left: 60px;
    position: relative;
    margin-top: 30px;
    padding-bottom: 30px;
}

.fa-step .step-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    background: #2116ce;
    text-align: center;
    border-radius: 100%;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    left: 15px;
    top: -3px;
}

.fa-step h3 {
    font-size: 18px;
    font-weight: 700;
    opacity: 0.9;
    margin-bottom: 0;
}
.fa-step p {
    font-size: var(--paragraph-font-size);
    opacity: 0.9;
    margin-bottom: 8px;
}
.fa-step a {
    color: #0014a1;
    font-weight: 600;
}
.fa-step::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #4c4d5d;
    top: 22px;
    left: 27px;
}
.fa-step:last-child::after {
    background: #85797b !important;
    height: 80% !important;
}




#roadmapContent h3{
    font-size: 20px;
    font-family: 'Gilroy' !important;
    color: white;
    font-weight: 700;
    margin-bottom: 15px;
}
#roadmapContent p{
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    color: #b9b9b9;
}
#rightSide{
    top: 100px;
    flex: 2;
}
#rightSide img{
    width: 100%;
    margin-top: 45px;
}
#leftSide{
    flex:4;
}
#teamContent .social{
    display: flex;
    margin-bottom: 15px;
}
#teamContent .social img{
    width: 18px;
    margin-right: 15px;
}
#teamContent .desc{
    font-size: 14px;
    color: white;
    opacity: 0.6;
    margin-bottom: 25px;
}
#teamContent .role{
    font-size: 12px;
    background: linear-gradient(46deg, #27293d 0%, rgb(67 103 162) 100%);
    color: white;
    border-radius: 4px;
    padding: 2px 10px;
}
#teamContent .name{
    font-size: 18px;
    font-weight: 700;
    margin-top: 7px;
    margin-bottom: 5px;
    color: white;
}
#teamContent h3{
    background: #18182a;
    font-size: 16px;
    font-weight: 600;
    padding: 25px;
    line-height: 25px;
    color: #d2d2d2;
    margin-bottom: 50px;
    border-radius: 5px;
}

#teamBg{
    background: linear-gradient(76deg, rgb(238 124 25) 0%, rgb(184, 69, 20) 31%, rgb(174, 57, 11) 59%, rgb(238 124 25) 98%) !important;
    color: white !important;
    position: relative;
    border-radius: 5px;
    margin-bottom: 50px;
    box-shadow: 2px 2px 30px #401c0c !important;
    padding: 25px 40px;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Gilroy';
    
}

.mint-info-list{
    margin-bottom: 15px;
    border-radius: 8px;
    padding: 10px 20px;
    position: relative;
    background-color:#28192387;
    text-align: left;
}
.mint-info-list span{
    background: #a08b8e;
    font-size: 11px;
    padding: 3px 8px;
    color: #ededed;
    font-weight: 700;
    margin-bottom: 25px;
    margin-right: 8px;
}
.mint-info-list span.wl{
    background: #eda958;
    color: white;
}
.mint-info-list span.nwl{
    background-color: #d62424;
}
.burn-item{
    border: 1px solid #2d2d47;
    background: rgba(0,0,0,0.4);
    margin-bottom: 15px;
    border-radius: 8px;
    padding: 10px 20px;
    position: relative;
}
.burn-item img{
    width: 80%;
    border-radius: 8px;
    margin-top: 15px;
}
.burn-item h4{
    font-size: 17px;
    font-weight: 700;
    color: white !important;
    opacity: 1;
    padding-bottom: 5px !important;
    padding-top: 15px;
}
.burn-item p{
    color: white;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
    margin-bottom: 50px;
}

.burn-item button{
    background: #3e3ad3;
    padding: 5px 15px;
    width: auto;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    margin: auto;
    left: 0;
    right: 0;
    width: 150px;
}
.mint-info-list .mint-info-content {
    margin-top: 50px;
    font-weight: 300;
    font-size: 13px;
    color: rgb(255, 255, 255);
}
.mint-info-list label{
    padding-left: 8px;
    color: rgb(255, 255, 255) !important;
    padding-right: 8px;
    font-weight: 800;
}
.mint-info-list b{
    
    padding: 0px 5px;

}
.countdown p{
    font-size: 13px;
    color: #909090;
    font-weight: 400;
}
.mint-info-list .countdown{
    display: flex;
    position: absolute;
    top: 10px;
    right: 20px;
}

.countdown span{
    background: transparent;
    font-weight: 800;
    font-size: 13.5px;
    padding: 0;
    margin-left: 10px;
    color: #ffffff;
}


.custom-tooltip {
    position: relative;
    cursor: pointer;
}

.custom-tooltip .tooltiptext {
    visibility: hidden;
    min-width: 250px;
    max-width: 400px;
    width: max-content;
    background-color: #09090f;
    color: #fff;
    padding: 10px 25px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: 13px;
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 0.5s;
}

.custom-tooltip:hover .tooltiptext,  .tooltiptext:hover {
visibility: visible;
opacity: 1;
}
.tooltipTitle{
    font-size: 17px;
    font-weight: 700;
    color: white;
    margin-bottom: 5px;
    display: block;
}
.tooltipContent{
    opacity: 0.6;
    margin-bottom: 15px;
}
.tooltiptext a{
    width: 100%;
    text-align: right;
    display: block;
    color: #ee7c19 ;
}



#panda1{
    position: fixed;
    width: 250px;
    bottom: -50px;
    left: -150px;
    animation: panda1 18s infinite;
    filter: brightness(0.8);
}

@keyframes panda1 {
    0%    {left:-150px; bottom: -45px;}
    2%    {bottom: -50px;}
    4%    {bottom: -45px;}
    6%    {bottom: -50px;}
    8%    {bottom: -45px;}
    10%    {bottom: -50px;}
    12%    {bottom: -45px;}
    14%    {bottom: -50px;}
    16%    {bottom: -45px;}
    18%    {bottom: -50px;}
    20%    {bottom: -45px;}
    22%    {bottom: -50px;}
    24%    {bottom: -45px;}
    26%    {bottom: -50px;}
    28%    {bottom: -45px;}
    30%    {bottom: -50px;}
    32%    {bottom: -45px;}
    34%    {bottom: -50px;}
    36%    {bottom: -45px;}
    38%    {bottom: -50px;}
    40%    {bottom: -45px;}
    42%    {bottom: -50px;}
    44%    {bottom: -45px;}
    46%    {bottom: -50px;}
    48%    {bottom: -45px;}
    50%  {left:50px; }
    70%  {left:50px; bottom: -45px;}
    75% {bottom: -250px; left: 50px;}
    76% {bottom: -250px; left: -150px;}
}
#panda2{
    position: fixed;
    width: 380px;
    bottom: -50px;
    left: -250px;
    animation: panda2 28s infinite;
}

@keyframes panda2 {
    0%    {left:-250px; bottom: -45px;}
    2%    {bottom: -50px;}
    4%    {bottom: -45px;}
    6%    {bottom: -50px;}
    8%    {bottom: -45px;}
    10%    {bottom: -50px;}
    12%    {bottom: -45px;}
    14%    {bottom: -50px;}
    16%    {bottom: -45px;}
    18%    {bottom: -50px;}
    20%    {bottom: -45px;}
    22%    {bottom: -50px;}
    24%    {bottom: -45px;}
    26%    {bottom: -50px;}
    28%    {bottom: -45px;}
    30%    {bottom: -50px;}
    32%    {bottom: -45px;}
    34%    {bottom: -50px;}
    36%    {bottom: -45px;}
    38%    {bottom: -50px;}
    40%    {bottom: -45px;}
    42%    {bottom: -50px;}
    44%    {bottom: -45px;}
    46%    {bottom: -50px;}
    48%    {bottom: -45px;}
    50%  {left:120px; }
    70%  {left:120px; bottom: -45px;}
    75% {bottom: -350px; left: 120px;}
    76% {bottom: -350px; left: -250px;}
}
#panda3{
    transform: rotateY(180deg);
    position: fixed;
    width: 350px;
    bottom: -50px;
    right: 50px;
    animation: panda3 25s infinite;
}
@keyframes panda3 {
    0%    {transform:scale(1)  rotateY(180deg); filter: brightness(1);}
    20%    {transform:scale(0.95)  rotateY(180deg); bottom: -55px; filter: brightness(0.8);}
    60%    {transform:scale(1)  rotateY(180deg); bottom:-50px; filter: brightness(1);}
    80%    {transform:scale(0.9)  rotateY(180deg); bottom:-60px; filter: brightness(0.6);}
  
}

#panda5{
    transform: rotateX(180deg);
    position: fixed;
    width: 100px;
    top: -200px;
    right: 50px;
    right: 0;
    left: 0px;
    margin:auto;
    animation: panda5 15s infinite;
}
@keyframes panda5 {
    0%    {top:-200px; filter: brightness(0.5);}
    30%  { top:-200px; filter: brightness(0.5);}
    35%   {top:-80px; filter: brightness(0.5);}
    38%   {top:-85px; filter: brightness(0.5);}
    43%   {top:-25px; filter: brightness(1);}
    50%   {top:-20px}
    55%  {top:-200px}
}
#leftSideContainer h1{
    margin-bottom: 0;
}


.mint-bar{
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 90px;
    box-shadow: 5px 5px 20px #150e13;
    background: #1a1017;
    left: 0;
    right: 0;
    z-index: 100;
    margin: auto;
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mint-info-list.highlight{
    border: 3px solid #281a25;
}
#panda6{
    transform: rotate(90deg);
    position: fixed;
    width: 100px;
    top: 0px;
    left: -200px;
    animation: panda6 26s infinite;
}
@keyframes panda6 {
    0%    {left:-200px; filter: brightness(0.5);}
    30%  { left:-200px; filter: brightness(0.5);}
    35%   {left:-80px; top:0px; filter: brightness(0.1);}
    68%   {left:-55px; top: 150px; filter: brightness(0.5);}
    78%   {left:-25px; top: 150px; filter: brightness(1);}
    88%   {left:-15px; top: 150px; filter: brightness(1);}
    75%  {left:-200px; top: 150px;}
}

.nav-item button{
}

.powered{
    font-size: 14px;
    color: black;
}
.powered img{
    width: 30px;
    margin-bottom: -7px;
    margin-left: 0px;
    filter: none;
}
.powered h3 a{
    color: white !important;
    font-size: 13px;
    padding-right: 8px !important;
}

#refundConnect{
    width: 50%;
    margin:auto;
}
.tab-content, .modal-body, .mint-goal-icon .tooltipContent{
    font-family: "Gilroy";
}
.progress{
    background-color: #634f51 !important; 
}
.progressTitle h2, .progress-container span{
    font-family: "Gilroy";
    font-size: 12px;
    font-weight: 500;
}
.progress{
    border-radius: 15px !important;
}
.progressTitle h2{
    font-size: 13px !important;
    font-weight: 500;
    margin-bottom: 5px;
}
.spanItem span{
    font-family: 'Gilroy' !important;
    font-weight: 800;
}